<script>
    import Financials from '@/services/Financials';
    import Orders from '@/services/Orders';
    import validationMessages from '@/components/validations'
    import {minLength, numeric, required, helpers} from "vuelidate/lib/validators";
    import Swal from "sweetalert2";

    export default {
        components:{
                validationMessages
            },
     props: ['transactionId','legacy'],

        watch: {
            transactionData: function(newVal) {
                this.status_e = newVal.status;
            }
        },
        validations: {
            amount: {
                required,
              numericOrDecimal: helpers.regex('numericOrDecimal', /^[0-9]+(\.[0-9]{1,2})?$/)
            },
            reason: {
                required,
                minLength: minLength(2)
            },
        },
        data() {
            return {
                showModal: false,
                orderDetails:[],
                transactionDetails:[],
                transactionData:[],
                refundsData:[],
                isLoading: false, 
                isBusy: false,
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                filter: null,
                filterOn: [],
                sortBy: "date",
                sortDesc: false,
                csrf_token: localStorage.getItem('csrf_token'),
                status_e: '',
                oldStatus: '',
                amount: '',
                reason: '',
                tryingToEdit: false,
                submitted: false,
                submittedR: false,
                isDisabled: false,
              fields: [
                    {
                        key: "refund_id",
                        label: "Refund Id",
                    },
                    {
                        key: "balance_transaction",
                        label: "Balance Transaction",
                    },
                    {
                        key: "created",
                        label: "Created",
                        sortable: true,
                    },
                    {
                        key: "currency",
                        label: "Currency",
                    },
                    {
                        key: "amount",
                        label: "Amount",
                        sortable: true,
                    },
                    {
                        key: "reason",
                        label: "Reason",
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true,
                    }
                ],
            };
        },
        computed:{
            rows() {
                return this.refundsData.length;
            },
             editTransactionTitle(){
                return 'Edit Transacation: ' + this.transactionData.transaction_id
            },
            oldStatusData(){
                return this.transactionData.status;
            }
        },
        methods: {
            resetModal() {
        this.transactionData = [];
        this.refundsData = [];
        this.amount = '';
        this.reason = '';
        this.submitted = false;
        this.submittedR = false;
        this.tryingToEdit = false;
        this.status_e = '';
        this.oldStatus = '';
        this.isLoading = false;
        this.isBusy = false;
    },

            async getTransactionDetails(){
                this.isLoading = true; 
                this.toggleBusy();
                await Financials.getTransaction(this.transactionId).then((response) => {
                    if (response.data.transaction && response.data.transaction.length > 0) {
            this.transactionData = response.data.transaction[0]; 
            this.refundsData = response.data.refunds; 
        } else {
            this.transactionData = {}; 
            this.refundsData = [];
        }
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }).finally(() => {
                    this.isLoading = false; 
                    this.toggleBusy();
                })
            },
            closeModal() {
                this.showModal = false;
            },
             async updateTransactionStatus(){

                this.tryingToEdit = true;
                this.submitted = true;

                try {
                    await Financials.updateTransaction({
                        transaction_id: this.transactionData.transaction_id,
                        status: this.status_e,
                        guid: this.transactionData.guid,
                        csrf_token: this.csrf_token
                    }).then((response) => {
                        if (response.data) {
                            this.updateOrder()
                        } else {
                            this.failedmsg('Transaction Status not updated')
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {
                        this.tryingToEdit = false;
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },

            async updateOrder(){
                try {
                    var oStatus = '';
                    if(this.status_e == 'processed' || this.status_e == 'processed_manually'){
                        oStatus = 'completed';
                    }else{
                        oStatus = this.status_e;
                    }
                    await Orders.updateOrder({
                        order_guid: this.transactionData.guid,
                        legacy: this.legacy,
                        order:{
                            status : oStatus
                        },
                        order_type: this.transactionData.type,
                        csrf_token: this.csrf_token
                    }).then((response) => {
                        this.successmsg(response);
                    }).catch(error => {
                        Financials.updateTransaction({
                            transaction_id: this.transactionData.transaction_id,
                            status: this.oldStatusData
                        }).then((response) => {
                        })
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {
                        this.refreshData()
                        this.closeModal();
                        this.tryingToEdit = false;
                        this.submitted = false;
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },

            async refundTransaction(){
                this.tryingToEdit = true;
                this.submittedR = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Financials.createRefund({
                            transaction_id: this.transactionData.transaction_id,
                            order_type: this.transactionData.type,
                            amount: this.amount,
                            reason: this.reason,
                            guid: this.transactionData.guid,
                        }).then((response) => {
                            if(response.data){
                                this.status_e = response.data.transaction_status
                            }else{
                                this.status_e = 'refunded'
                            }
                            this.updateOrder()
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },

            resetProps(){
                this.amount = '';
                this.reason = '';
                this.submitted = false;
                
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

        }
    };
</script>

<template>
    <b-modal @shown="resetModal();getTransactionDetails()" v-model="showModal" id="view_transaction" title="View Transaction Details" title-class="font-18" size="xl" centered>
         <div class="row mb-3" v-if="isLoading">
            <div class="col-sm-12 text-center">
                <b-spinner label="Loading..."></b-spinner>
                <h4 class="font-size-16 mb-2">Loading transaction details...</h4>
            </div>
        </div>



        <div class="row mb-3" v-if="!Object.keys(transactionData).length  && !refundsData.length && !isLoading">
            <div class="col-sm-12">
                <h4 class="font-size-16 mb-2 text-center">
                    Transactions are synced every hour. Please try again later or contact support.
                </h4>
            </div>
        </div>

        <div class="row mb-3" v-else-if="Object.keys(transactionData).length > 0">
            <div class="col-sm-6">
               <p class="font-size-16 mb-2"><span class="text-strong">Transaction ID:</span> {{transactionData.transaction_id}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Order ID:</span> {{transactionData.order_id}}</p>
               <p class="font-size-16 mb-2"><span class="text-strong">Customer:</span>
                   <router-link :to="{ path: `/customers/profile/${transactionData.customer_id}`}">
                       <span v-if="transactionData.first_name || transactionData.last_name"> {{ transactionData.first_name + ' ' +  transactionData.last_name }}</span>
                   </router-link></p>
               <p class="font-size-16 mb-2"><span class="text-strong">Type:</span> {{transactionData.type}}</p>
               <p class="font-size-16 mb-2"><span class="text-strong">Order Status:</span> <span class="font-size-16"
                  v-html="format_status(transactionData.status)"> </span></p>
            </div>
            <div class="col-sm-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Amount:</span> {{transactionData.amount}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Fee:</span> {{transactionData.fee}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Amount Refunded:</span> {{transactionData.amount_refunded}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Created:</span> {{transactionData.created}}</p>
            </div>

            <hr>

            <div class="row mt-3">
                <div class="col-sm-12">
                    <h5>Refunds</h5>
                </div>
                <div class="col-sm-12">
                    <div class="table-responsive mb-0">
                        <b-table
                            :items="refundsData"
                            :busy="isBusy"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            show-empty
                            empty-text="No Data Found"
                        >

                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>

                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-end">
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination
                                            v-model="currentPage"
                                            :total-rows="rows"
                                            :per-page="perPage"
                                    ></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br><br><hr/>


             <div class="row">
             <h5>Edit Transaction</h5>
             <br><br>

            <div class="col-sm-6">
              <h5>Refund Amount</h5>
              <form @submit.prevent="refundTransaction">

                <!-- Conditional message display -->
                <div v-if="transactionData.amount === transactionData.amount_refunded" class="mb-3">
                  <b-alert variant="danger" show>
                    Full refund already processed
                  </b-alert>
                </div>

                <b-form-group label="Amount" label-for="formrow-amount-input" class="mb-3">
                  <b-form-input
                      v-model.number="amount"
                      type="number"
                      id="amount"
                      aria-describedby="amount-feedback"
                      :class="{'is-invalid': submittedR && $v.amount.$error}"
                      :disabled="transactionData.amount === transactionData.amount_refunded"
                  ></b-form-input>
                  <validationMessages v-if="submittedR" :fieldName="'Amount'" :validationName="$v.amount"></validationMessages>
                </b-form-group>

                <b-form-group label="Reason" label-for="reason" class="mb-3">
                  <b-form-textarea
                      id="reason"
                      v-model="reason"
                      placeholder="Enter reason for refund"
                      rows="3"
                      max-rows="6"
                      :class="{'is-invalid': submittedR && $v.reason.$error}"
                      :disabled="transactionData.amount === transactionData.amount_refunded"
                  ></b-form-textarea>
                  <validationMessages v-if="submittedR" :fieldName="'Reason'" :validationName="$v.reason"></validationMessages>
                </b-form-group>

                <b-button
                    variant="primary"
                    @click="refundTransaction"
                    class="float-end"
                    :disabled="tryingToEdit || transactionData.amount === transactionData.amount_refunded"
                >
                  <b-spinner v-show="tryingToEdit" small></b-spinner>
                  Refund
                </b-button>
              </form>
            </div>

            <div class="col-sm-6">
              <h5>Change Status</h5>
              <form @submit.prevent="updateTransactionStatus">
                <b-form-group label="Status" label-for="formrow-status-input" class="mb-3">
                  <select v-model="status_e" class="form-select">
                    <option disabled value=" ">undefined</option>
                    <option value="processed">processed</option>
                    <option value="processed_manually">processed manually</option>
                    <option value="refunded">refunded</option>
                    <option value="partially_refunded">partially refunded</option>
                  </select>
                </b-form-group>
                <b-button
                    variant="primary"
                    @click="updateTransactionStatus"
                    class="float-end"
                    :disabled="tryingToEdit"
                >
                  <b-spinner v-show="tryingToEdit" small></b-spinner>
                  Update Status
                </b-button>
                <input type="hidden" name="csrf_token" v-model="csrf_token">
              </form>
            </div>
          </div>




        </div>





        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>
    </b-modal>
</template>